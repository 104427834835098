import React, {Fragment, useState, useEffect} from 'react';
import { Button, Divider, Avatar, Typography, Row, Col, Layout, Select, Card, Drawer, notification } from 'antd';
import { Filters, IPlan, IPlanEntry } from "../types"
import { Link } from "react-router-dom";

const { Title, Paragraph, Text } = Typography;

export const PlanEntry = (props: {plan: IPlan, planEntry: IPlanEntry, highlight: boolean, showRecord: boolean}) =>
  <>
    <Link to={`/plans/${props.plan.slug}/${props.planEntry.id}`} style={{ color: 'inherit'}}>
  <div key={props.planEntry.id} style={props.highlight ? {backgroundColor: "#ffe1a9"}: {}}>
    <Row>
      <Col>
        <Title style={{"display": "inline-block", marginRight: "10px"}} level={4}>{props.planEntry.title} ({props.planEntry.human_duration})</Title>
      </Col>
      {props.showRecord && <Col flex="200px">
        {<Button onClick={(e) => 
          {
            let history : Array<{id: number, date: Date, planEntryId: number, planId: number}> = window.localStorage.getItem("history") ? JSON.parse(window.localStorage.getItem("history")!) : [];
            history.push({id: history.length, date: new Date(), planEntryId: props.planEntry.id, planId: props.plan.id})
            window.localStorage.setItem("history", JSON.stringify(history))
            notification.success({
              message: 'Workout Recorded',
              description:
                `${props.planEntry.title}`,
              duration: 1,
              placement: 'bottomRight'
            })
          }
        }>Record</Button>}</Col>}
      </Row>
      <p>{props.planEntry.description}</p>
    {props.planEntry.video && <video style={{display: 'inline-block'}} src={props.planEntry.video.mp4_720} poster={props.planEntry.video_thumbnail} controls preload="none" />}
  </div>
  </Link>
  <Divider />
  </>