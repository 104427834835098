import React, {Fragment, useState, useEffect, ReactNode, useReducer} from 'react';
import { Typography, PageHeader, Layout, Select, Skeleton, Button, Timeline, Popconfirm, notification, Tabs} from 'antd';
import { Routes, Route, Link, useParams } from "react-router-dom";
import './App.css';
import { Filters, IPlan, IPlanEntry, IRecordEntry, IRecordStorageEntry, IFavoriteStorageEntry } from "./types"
import { SidebarForm } from "./components/SidebarForm"
import { PlanList } from "./components/PlanList";
import { Plan } from "./components/Plan";
import { PlanEntry } from "./components/PlanEntry";
const { Title, Paragraph, Text } = Typography;
const { Header, Content, Footer, Sider } = Layout;
const { Option } = Select;
const { TabPane } = Tabs;


const Main = (props: {plans: Array<IPlan>}) => {
  const plans = props.plans
  const [filters, setFilters] = useState<Filters>({
    coaches: [],
    difficulty_levels: []
  });
  
  let filteredPlans = () => {
    const coaches = new Set(filters.coaches);
    const difficulty_levels = new Set(filters.difficulty_levels);
    let p0 = plans.filter( p => { 
          return coaches.size == 0 || new Set(p.coaches.flatMap(c => c.coach_info.id).filter(c => coaches.has(c))).size > 0
    });

    let p1 = p0.filter( p => difficulty_levels.size == 0 || difficulty_levels.has(p.difficulty_level));
    return p1.sort((p1, p2) => { return new Date(p1.release_date).getTime() - new Date(p2.release_date).getTime();});
  };

  useEffect(() => {

  }, [filters]);

  return (
      <Layout>
        <Sider width={300}>
          <SidebarForm plans={plans} setFilters={setFilters}></SidebarForm>
          <div style={{textAlign: "center", color: "white", fontStyle: 'italic'}}>Series: { filteredPlans().length }</div>
          </Sider>
        <Content>
          <PlanList plans={filteredPlans()} showMore={false}/>
        </Content>
      </Layout>
    );
}

function BaseLayout(props: any)  {
  const routes = [
    {
      path: '/',
      breadcrumbName: 'Home',
    },
    {
      path: 'index',
      breadcrumbName: props.title,
    }
  ];

  useEffect(() => {
    document.title = props.title
  }, [props.title]);

  function itemRender(route: any, params: any, routes: Array<any>, paths: Array<string>) {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={route.path}>{route.breadcrumbName}</Link>
    );
  }

  return (
    <Layout>
    <PageHeader
      className="site-page-header-responsive"
      onBack={() => window.history.back()}
      title={ props.title }
      breadcrumb={{ 
        routes: routes,
        itemRender: itemRender
      }}
      extra={[
        <>
        <Link to="/me/history"><Button key="1" type="primary">
          History
        </Button></Link>
         <Link to="/me/favorites"><Button key="2" type="primary">
         Favorites
       </Button></Link>
       </>,
      ]}
    >
      {props.children}
    </PageHeader>
    </Layout>
  )
}

const PlanPage = (props: {plans: Array<IPlan>}) => {
  let { slug } = useParams<"slug">();
  let plan = props.plans.find(p => p.slug == slug)
  let title = plan ? `Series "${plan.title}"` : `Series ${slug} not found`
  
  return (
    <BaseLayout title={title}>
       <Content> { plan && <Plan key={plan.id} plan={plan} showMore={true} />} </Content>
    </BaseLayout>
  );
}

const FavoritesPage = (props: {plans: Array<IPlan>}) => {
  const [favorites, setFavorites] = useReducer((prev: Array<IFavoriteStorageEntry>, cur: Array<IFavoriteStorageEntry>) => {
    window.localStorage.setItem('favorites', JSON.stringify(cur));
    return cur;
  }, JSON.parse(window.localStorage.getItem('favorites') ? window.localStorage.getItem('favorites')! : "[]"));

  let filteredPlans = props.plans.filter( p => favorites.map(f => f.planId).indexOf(p.id) != -1)
  let title = filteredPlans ? `Favorites` : `No favorites found`

  return (
    <BaseLayout title={title}>
       <Content> { filteredPlans && <PlanList plans={filteredPlans} showMore={false} /> } </Content>
    </BaseLayout>
  );
}

const HistoryPage = (props: {plans: Array<IPlan>}) => {
  const [history, setHistory] = useReducer((prev: Array<IRecordStorageEntry>, cur: Array<IRecordStorageEntry>) => {
    window.localStorage.setItem('history', JSON.stringify(cur));
    return cur;
  }, JSON.parse(window.localStorage.getItem('history') ? window.localStorage.getItem('history')! : "[]"));

  let getEntries = () : Array<IRecordEntry> =>  {
    let entries0 : Array<{id: number, date: Date, planEntry?: IPlanEntry, plan?: IPlan}> = history.map( e => {
      let plan = props.plans.find(p => Number(p.id) == e.planId)
      let planEntry = plan?.entries.find(p => p.id == e.planEntryId)

      return {id: e.id, date: e.date, plan: plan, planEntry: planEntry}
    })

    return entries0.filter(e => e.plan && e.planEntry).map(e => {
      return {
      id: e.id,
      date: e.date,
      plan: e.plan!,
      planEntry: e.planEntry!
    }
  })
}

  let deleteWorkout = (entry: IRecordEntry) => {
    let rawEntry = {id: entry.id, date: entry.date, planId: entry.plan.id, planEntryId: entry.planEntry.id}
    let newData = history.filter(e => e.id !== rawEntry.id)
    setHistory(newData)
    if (newData.length < history.length) {
      notification.success({
        message: 'Workout Removed',
        description:
          `${entry.planEntry.title}`,
        duration: 1,
        placement: 'bottomRight'
      })
    } else {
      notification.error({
        message: 'Failed to remove workout',
        description:
          `${entry.planEntry.title}`,
        duration: 1,
        placement: 'bottomRight'
      })
    }
  }
  
  return (
    <BaseLayout title="Recorded workouts">
       <Content> 
         <Timeline reverse={true}>
         {getEntries().length == 0 && <Text> No workouts recorded.</Text>}
         {getEntries().map(e => (
           <Timeline.Item key={`${e.date.toString()}`}>
            <Text>Date: {new Date(e.date).toLocaleDateString()}</Text> &nbsp;
            <Text style={{marginRight: "10px"}}>Start Time: {new Date(e.date).toLocaleTimeString()}</Text>
            <Button danger><Popconfirm
              title="Are you sure to delete this task?"
              onConfirm={() => deleteWorkout(e)}
              okText="Yes"
              cancelText="No"
            >
              Delete
            </Popconfirm></Button>
            <PlanEntry key={e.date.toString()} plan={e.plan} planEntry={e.planEntry} highlight={false} showRecord={false}/>
            </Timeline.Item>
          ))} 
          </Timeline>
          
      </Content>
    </BaseLayout>
  );
}

const PlanEntryPage = (props: {plans: Array<IPlan>}) => {
  let { slug } = useParams<"slug">();
  let { workout } = useParams<"workout">();
  let plan = props.plans.find(p => p.slug == slug)
  let title = plan ? `Series "${plan.title}"` : `Series ${slug} not found`
  
  return (
    <BaseLayout title={title}>
       <Content> { plan && <Plan key={plan.id} plan={plan} showMore={true} highlight={Number(workout)}/>} </Content>
    </BaseLayout>
  );
}

const CoachPage = (props: {plans: Array<IPlan>}) => {
  let { slug } = useParams<"slug">();
  let filteredPlans = props.plans.filter( p => p.coaches.map(c => c.coach_info.slug).indexOf(slug!) >= 0)
  let title = filteredPlans ? `Coach "${slug}"` : `Coach ${slug} not found`
  
  return (
    <BaseLayout title={title}>
       <Content> { filteredPlans && <PlanList plans={filteredPlans} showMore={false} /> } </Content>
    </BaseLayout>
  );
}

const CategoryPage = (props: {plans: Array<IPlan>}) => {
  let { slug } = useParams<"slug">();
  let filteredPlans = props.plans.filter( p => p.category.slug == slug || p.subcategory?.slug == slug)
  let title = filteredPlans ? `Category "${slug}"` : `Category ${slug} not found`

  return (
    <BaseLayout title={title}>
       <Content> { filteredPlans && <PlanList plans={filteredPlans} showMore={false} /> } </Content>
    </BaseLayout>
  );
}

const DifficultyPage = (props: {plans: Array<IPlan>}) => {
  let { slug } = useParams<"slug">();
  let filteredPlans = props.plans.filter( p => p.difficulty_level == slug)
  let title = filteredPlans ? `Difficulty "${slug}"` : `Difficulty ${slug} not found`

  return (
    <BaseLayout title={title}>
       <Content> { filteredPlans && <PlanList plans={filteredPlans} showMore={false} /> } </Content>
    </BaseLayout>
  );
}

const IntensityPage = (props: {plans: Array<IPlan>}) => {
  let { slug } = useParams<"slug">();
  let filteredPlans = props.plans.filter( p => p.intensity_level == slug)
  let title = filteredPlans ? `Intensity "${slug}"` : `Intensity ${slug} not found`

  return (
    <BaseLayout title={title}>
       <Content> { filteredPlans && <PlanList plans={filteredPlans} showMore={false} /> } </Content>
    </BaseLayout>
  );
}

function App() {
  const [plans, setPlans] = useState<Array<IPlan>>([]);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    const p = process!.env!.REACT_APP_DATA_FILES!.split(",").map(path => fetch(path).then(res => res.json()));

    let plans  = new Map<number, IPlan>();
    let videos  = new Map();
    let coaches  = new Map();
    let categories = new Map();
    
    Promise.all(p).then(results => {
      let entries : any[] = results.reduce((acc, val) => acc = [...acc, ...val], [])
      entries.forEach(entry => {
        // let video : Video = entry.video;
        var plan : IPlan =  entry.plan;
        // videos.set(entry.video.id, video);
        
        if (!plans.has(plan.id)) {
          plan.entries = [];
          plans.set(entry.plan.id, plan);
        }
        plans.get(plan.id)!.entries.push(entry);
        setLoaded(true);
      });
  
      setPlans(Array.from(plans.values()));
    });
  }, []);

  return (
    <div className="App">
      {loaded ?
      <Routes>
        <Route path="/" element={<Main plans={plans} />} />
        <Route path="/me/history" element={<HistoryPage plans={plans} />} />
        <Route path="/me/favorites" element={<FavoritesPage plans={plans} />} />
        <Route path="/plans/:slug" element={<PlanPage plans={plans}/>} />
        <Route path="/plans/:slug/:workout" element={<PlanEntryPage plans={plans}/>} />
        <Route path="/coaches/:slug" element={<CoachPage plans={plans}/>} />
        <Route path="/category/:slug" element={<CategoryPage plans={plans}/>} />
        <Route path="/difficulty/:slug" element={<DifficultyPage plans={plans} />} />
        <Route path="/intensity/:slug" element={<IntensityPage plans={plans} />} />
      </Routes>
      : <Skeleton / >}
    </div>
  );
}

export default App;
