import React, {Fragment, useState, useEffect} from 'react';
import { Button, Divider, Avatar, Typography, Row, Col, Layout, Select, Card, Drawer } from 'antd';
import { Filters, IPlan, ICoach } from "../types"
import { Plan } from "../components/Plan";
const { Title, Paragraph, Text } = Typography;
const { Header, Content, Footer, Sider } = Layout;
const { Option } = Select;

export const PlanList = (props: {plans: IPlan[], showMore: boolean}) => <div className="plans">
{props.plans.map(p => (
  <Plan key={p.id} plan={p} showMore={props.showMore} />
))}
</div>