import React, {Fragment, useState, useEffect} from 'react';
import { Button, Divider, Avatar, Typography, Row, Col, Layout, Select, Card, Drawer } from 'antd';
import { Filters, IPlan, ICoach } from "../types"
import { CoachDrawerEntry } from "../components/CoachDrawerEntry";
import { Link } from "react-router-dom";

const { Title, Paragraph, Text } = Typography;


export const CoachDrawerList = (props: {coaches: ICoach[]}) => <span className="coaches">
<Text>Coaches: </Text>
{props.coaches.map( (c, idx) => (
  <CoachDrawerEntry key={c.coach_info.id} coachEntry={c} entryId={idx} />
))}
</span>