import React, {Fragment, useState, useEffect} from 'react';
import { Button, Divider, Avatar, Typography, Row, Col, Layout, Select, Card, Drawer } from 'antd';
import { Filters, IPlan } from "../types"
import { PlanEntry } from "../components/PlanEntry";
import { Link } from "react-router-dom";

const { Title, Paragraph, Text } = Typography;

export const PlanEntryList = (props: {plan: IPlan, highlight?: number}) => <div className="plans">
{props.plan.entries.map(p => (
  <PlanEntry key={p.id} plan={props.plan} planEntry={p} highlight={props.highlight ? p.id == props.highlight!: false} showRecord={true} />
))}
</div>