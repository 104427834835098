import React, {useReducer, useEffect, useState} from 'react';
import { Button, Divider, Avatar, Typography, Row, Col, notification } from 'antd';
import { Filters, IPlan, IFavoriteStorageEntry } from "../types"
import { CoachDrawerEntry } from "../components/CoachDrawerEntry";
import { PlanMd } from "../components/PlanMd";
import { PlanEntryList } from "../components/PlanEntryList";
import { Link } from "react-router-dom";

const { Title, Paragraph, Text } = Typography;

export const Plan = (props: {plan: IPlan, showMore: boolean, highlight?: number}) => {
  const [favorites, setFavorites] = useReducer((prev: Array<IFavoriteStorageEntry>, cur: Array<IFavoriteStorageEntry>) => {
    window.localStorage.setItem('favorites', JSON.stringify(cur));
    return cur;
  }, JSON.parse(window.localStorage.getItem('favorites') ? window.localStorage.getItem('favorites')! : "[]"));

  const [isFavorite, setIsFavorite] = useState<boolean>(favorites.map(f => f.planId).indexOf(props.plan.id) != -1)

    return (
    <div key={props.plan.id} style={{borderBottom: "2px solid black" }}>
      <Row>
      <Col><Link to={`/plans/${props.plan.slug}`}><Title style={{"display": "inline-block", marginRight: "10px"}} level={3}>{props.plan.title}</Title></Link></Col>
      <Col flex="200px">
        {!isFavorite ?
        <Button onClick={(e) => 
          {
            favorites.push({id: favorites.length, date: new Date(), planId: props.plan.id})
            setFavorites(favorites)
            setIsFavorite(true)
            notification.success({
              message: 'Favorite Recorded',
              description:
                `${props.plan.title}`,
              duration: 1,
              placement: 'bottomRight'
            })
          }
        }>Favorite</Button>
        : <Button onClick={(e) => 
          {
            setFavorites(favorites.filter( f => f.planId != props.plan.id))
            setIsFavorite(false)
            notification.success({
              message: 'Favorite Recorded',
              description:
                `${props.plan.title}`,
              duration: 1,
              placement: 'bottomRight'
            })
          }
        }>Unfavorite</Button>}
      </Col>
      </Row>
      <p>{props.plan.description}</p>
      <PlanMd plan={props.plan} />
      {!props.showMore ? 
      <Link to={`/plans/${props.plan.slug}`}><Button block={true}>View plan</Button></Link> :
      <PlanEntryList plan={props.plan} highlight={props.highlight} />
      }
    </div>);
  }