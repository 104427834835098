import React, {Fragment, useState, useEffect} from 'react';
import { Button, Divider, Avatar, Typography, Row, Col, Layout, Select, Card, Drawer } from 'antd';
import { Filters, IPlan, ICoach } from "../types"
import { CoachEntry } from "../components/CoachEntry";
import { Link } from "react-router-dom";

const { Title, Paragraph, Text } = Typography;


export const CoachDrawerEntry = (props: {coachEntry: ICoach, entryId: number}) => {
    const [visible, setVisible] = useState(false);
    const showDrawer = () => {
      setVisible(true);
    };
    const onClose = () => {
      setVisible(false);
    };
    return (
      <>
        {props.entryId > 0 && <Text>, </Text>}
        <Typography.Link onClick={showDrawer}>
          {props.coachEntry.first_name} {props.coachEntry.last_name}
        </Typography.Link>
        <Drawer title={`${props.coachEntry.first_name} ${props.coachEntry.last_name}`} placement="right" onClose={onClose} visible={visible}>
        <Avatar src={props.coachEntry.coach_info.user.profile_photo.url} size={128} />
        <p>{props.coachEntry.coach_info.bio}</p>
        <Link to={`/coaches/${props.coachEntry.coach_info.slug}`}><Button>View all series</Button></Link>
        </Drawer>
      </>
    );
  };