import React, {Fragment, useState, useEffect} from 'react';
import { Button, Divider, Avatar, Typography, Row, Col, Layout, Select, Card, Drawer } from 'antd';
import { IPlan } from "../types"
import { CoachDrawerList } from "../components/CoachDrawerList";
import { Link } from "react-router-dom";

const { Title, Paragraph, Text } = Typography;

export const PlanMd = (props: {plan: IPlan}) => 
  <>
  <span>
    Published: {new Date(props.plan.release_date).toLocaleDateString()} &nbsp;
    Difficult level: <Link to={`/difficulty/${props.plan.difficulty_level}`}>{props.plan.difficulty_level}</Link> &nbsp;
    Intensity level: <Link to={`/intensity/${props.plan.intensity_level}`}>{props.plan.intensity_level}</Link> &nbsp; 
    Workout count: {props.plan.workout_count} &nbsp; 
    Members: {props.plan.member_count} &nbsp;
    Category: <Link to={`/category/${props.plan.category.slug}`}>{props.plan.category.title}</Link> 
    {props.plan.subcategory! && <>
    <Text> / </Text>
    <Link to={`/category/${props.plan.subcategory.slug}`}>{props.plan.subcategory!.title}</Link>
    <Text>&nbsp;</Text>
    </>}
  </span>
  <CoachDrawerList coaches={props.plan.coaches} />
  </>