import React, {Fragment, useState, useEffect} from 'react';
import { Button, Divider, Avatar, Typography, Row, Col, Layout, Select, Card, Drawer } from 'antd';
import { Filters, IPlan, ICoach } from "../types"

const { Title, Paragraph, Text } = Typography;
const { Header, Content, Footer, Sider } = Layout;
const { Option } = Select;

export class SidebarForm extends React.Component<{plans: Array<IPlan>, setFilters: any}> {
    coaches() : Array<ICoach> {
      const m = new Map<number, ICoach>();
      this.props.plans.flatMap( p => p.coaches).forEach( c => {
        m.set(c.coach_info.id, c);
      });
      return Array.from(m.values());
    }
  
    difficultyLevels() : Array<string> {
      const s = new Set<string>();
      this.props.plans.forEach( p => s.add(p.difficulty_level));
      return Array.from(s);
    } 
  
    updateInstructors(value: Array<number>) {
      this.props.setFilters((prevState: Filters) => ({...prevState,  coaches: value}));
    }
  
    updateDifficultyLevels(value: Array<string>) {
      this.props.setFilters((prevState: Filters) => ({...prevState,  difficulty_levels: value}));
    }
    
    render() {
        return (<>
        <Card title="Instructors">
          <Select defaultValue={[]} style={{ width: 250 }} mode="multiple" onChange={this.updateInstructors.bind(this)} filterOption={(input, option) =>
            option!.children!.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
          } optionLabelProp="label">
            {this.coaches().map( c =>
              <Option key={`c-${c.coach_info.id}`} value={c.coach_info.id} label={`${c.first_name} ${c.last_name}`}>{`${c.first_name} ${c.last_name}`}</Option> 
            )}
          </Select>
          </Card>
          <Card title="Difficulty">
          <Select defaultValue={[]} style={{ width: 250 }} mode="multiple" onChange={this.updateDifficultyLevels.bind(this)}>
            {this.difficultyLevels().map( d =>
              <Option key={d} value={d}>{d}</Option> 
            )}
          </Select>
          </Card>
          </>
      );
    }
  };